<template>
  <BaseLayout v-slot>
    <FilterSystem />
    <Breadcrumbs />
    <CategoryOverview v-if="category.product_count == 0" />
    <div class="container" v-if="category.product_count > 0">
      <div class="row">
        <ProductFilter class="col-lg-3" />
        <ProductsListingV2 class="col-lg-9" />
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import ProductFilter from "@/esf_lommel_dt1_europe/core/components//ProductFilter";
import CategoryOverview from "@/esf_lommel_dt1_europe/core/components//CategoryOverview";
import ProductsListingV2 from "@/esf_lommel_dt1_europe/core/components//ProductListingV2";
import FilterSystem from "@/esf_lommel_dt1_europe/core/components//FilterSystem";
export default {
  name: "CategoryOverviews",
  components: {
    BaseLayout,
    Breadcrumbs,
    ProductFilter,
    ProductsListingV2,
    CategoryOverview,
    FilterSystem,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    categories() {
      return this.category.children;
    },
  },
  data() {
    return {
      //    bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
