<template>
  <div
    class="d-flex justify-content-end justify-content-md-between align-items-start align-items-xl-center content-filter"
  >
    <div
      class="selected-filters d-none d-md-flex flex-wrap align-items-center"
    ></div>
    <div class="select-wrapper">
      <b-form-select
        id="sorting"
        v-model="sorting"
        :options="selectOptions"
        class="select"
      />
    </div>
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "CategoryPageContentFilter",
  computed: {
    selectOptions() {
      const sortOpt = [];
      config.sortingOptions.forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    sorting: {
      get: function () {
        return this.$store.getters["category/getSorting"];
      },
      set: function (val) {
        this.$store.commit("category/setSorting", val);
      },
    },
  },
  data: () => ({
    filterType: "Merken",

    selectedFilterItems: [
      { id: "selectedFilter01", name: "Castelli" },
      { id: "selectedFilter02", name: "Nalini" },
      { id: "selectedFilter03", name: "Rogelli" },
      { id: "selectedFilter04", name: "21Virages" },
      { id: "selectedFilter05", name: "Alpinestars" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.content-filter {
  padding-top: 29px;
  padding-bottom: 21px;

  @include media("screen", ">=tablet") {
    padding-top: 18px;
  }
}

.filter-type {
  font-size: 16px;
  font-weight: normal;
  margin-right: 12px;
}

.filter-items {
  font-size: 14px;
  li {
    margin-top: 10px;
    margin-bottom: 10px;
    + li {
      margin-left: 14px;
    }
  }
}

.filter-items-btn {
  border: 1px solid #000;
  padding: 4px 22px;
  transition: none;
  display: block;

  &:hover {
    background: #ff8000;
    color: white !important;
    border-color: #ff8000;
  }
}

.select-wrapper {
  position: relative;
}

select {
  font-size: 14px;
  border: 1px solid #000;
  padding: 8px 55px 8px 10px;
  line-height: 1;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}
</style>
