<template>
  <section class="page-content">
    <CategoryPageContentFilter />
    <FilteredProducts />
    <!--<b-link
      href="#"
      class="load-more d-block text-center font-weight-normal py-25"
      ><i class="lnr lnr-hourglass mr-6"></i>Meer laden</b-link
    >-->
  </section>
</template>

<script>
import CategoryPageContentFilter from "@/esf_lommel_dt1_europe/core/components//CategoryPageContentFilter";
import FilteredProducts from "@/esf_lommel_dt1_europe/core/components//FilteredProducts";
export default {
  name: "CategoryPageContent",
  components: {
    CategoryPageContentFilter,
    FilteredProducts,
  },
};
</script>

<style lang="scss" scoped>
.load-more {
  font-size: 16px;
}
</style>
