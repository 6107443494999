<template>
  <div class="container">
    <div class="banner" :style="{ backgroundImage: `url(${bannerBg})` }"></div>
    <b-row v-if="category.children_count > 0">
      <b-col v-for="(category, i) of categories" :key="`${category} ${i}`">
        <b-link class="category-link" :to="$link(`/` + category.url_path)">
          <div class="category-box">
            <div class="img">
              <b-img-lazy
                v-if="category.image != null"
                :src="category.image"
                :alt="category.name"
                fluid
              ></b-img-lazy>
            </div>
            <div class="text">
              <p>{{ category.name }}</p>
            </div>
          </div>
        </b-link>
      </b-col>
    </b-row>
    <b-row v-if="category.children_count == 0">
      <div style="width: 100%; text-align: center">
        {{ $t("no_products_found") }}
      </div>
    </b-row>
  </div>
</template>
<script>
import bannerImage from "@/esf_lommel_dt1_europe/assets/DT1Pics/CollectieFoto.jpg";

export default {
  name: "CategoryOverview",
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    categories() {
      return this.category.children;
    },
  },
  data() {
    return {
      bannerBg: bannerImage,
      //categories: mockCategory,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.banner {
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-bottom: 8px;
}

.container {
  max-width: 1200px;
}

.row {
  margin: 0 -17px 20px;

  @include media("screen", ">=tablet") {
    margin: 0 -34px 40px;
  }

  @include media("screen", ">=desktop") {
    margin-bottom: 112px;
  }
}

.col {
  display: flex;
  flex-basis: auto;
  padding: 16px 17px;
  flex-grow: 0;
  width: 50%;

  @include media("screen", ">=tablet") {
    width: 33.333%;
    padding: 32px 34px;
  }

  @include media("screen", ">=desktop") {
    width: 20%;
  }
}

.category-link {
  display: flex;
  width: 100%;
}

.category-box {
  border: 1px solid #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 1.1;

  p {
    margin-bottom: 0;
  }
}

.img {
  height: 182px;
  display: flex;
  align-items: center;
  z-index: -1;
  img {
    max-width: 100%;
  }
}

.text {
  display: flex;
  align-items: center;
  min-height: 30px;
}
</style>
