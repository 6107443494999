<template>
  <div>
    <AdvertisingBanner class="col-12 my-30 mt-lg-0" />
    <!-- <BestSellers /> -->
    <CategoryPageContent />
    <section class="bottom-txt">
      <div v-html="category.description"></div>
    </section>
  </div>
</template>

<script>
import CategoryPageContent from "@/esf_lommel_dt1_europe/core/components//CategoryPageContent";
import AdvertisingBanner from "@/esf_lommel_dt1_europe/core/components//AdvertisingBanner";
//import BestSellers from "@/esf_lommel_dt1_europe/core/components//BestSellers";
export default {
  name: "ProductListingV2",
  components: {
    //BestSellers,
    CategoryPageContent,
    AdvertisingBanner,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
h3,
.h3 {
  font-weight: 400;
  margin-bottom: 10px;
}

.bottom-txt {
  font-size: 14px;
  line-height: 1.72;
  padding-bottom: 14px;
  padding-top: 25px;

  @include media("screen", ">=tablet") {
    padding-top: 0;
    padding-bottom: 73px;
  }
}
</style>
