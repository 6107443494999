<template>
  <div>
    <div v-for="(productList, index) of productsChunks" :key="index">
      <ProductsSection :products-list="productList" />
      <!--<AdvertisingBanner class="col-12 mt-md--10 my-35" />-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ProductsSection from "@/esf_lommel_dt1_europe/core/components/ProductsSection";
//import AdvertisingBanner from "@/esf_lommel_dt1_europe/core/components//AdvertisingBanner";
import chunkArray from "@/esf_lommel_dt1_europe/helpers/chunkArray";

export default {
  name: "FilteredProducts",
  components: {
    ProductsSection,
    //AdvertisingBanner,
  },
  computed: {
    productsChunks() {
      return chunkArray(this.$store.getters["category/getShowProducts"], 12);
    },
  },
  methods: mapActions(["setProductStatus"]),
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.mt-md---10 {
  @include media("screen", ">=tablet") {
    margin-top: -10px !important;
  }
}
</style>
